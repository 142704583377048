import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faFacebookSquare, faInstagram } from "@fortawesome/free-brands-svg-icons";

export default function Footer({ param }: Readonly<{ param?: string }>) {
    console.log(param);
    return (
        <footer className={`relative bg-${param !== undefined ? `${param} mt-16` : "gray-50"} pt-8 pb-6`}>
            <div
                className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
                style={{ transform: "translateZ(0)" }}
            >
                <svg
                    className="absolute bottom-0 overflow-hidden"
                    xmlns="http://www.w3.org/2000/svg"
                    preserveAspectRatio="none"
                    version="1.1"
                    viewBox="0 0 2560 100"
                    x="0"
                    y="0"
                >
                    <polygon className={`text-${param ?? "gray-50"} fill-current`} points="2560 0 2560 100 0 100"></polygon>
                </svg>
            </div>
            <div className="container mx-auto px-4">
                <div className="flex flex-wrap items-center md:justify-between justify-center">
                    <div className="w-full md:w-4/12 px-4 mx-auto text-center">
                        <div className="text-sm text-blue-gray-500 font-semibold py-1">Copyright © Timi Team</div>
                    </div>
                </div>
            </div>
        </footer>
    );
}
