import { faAndroid, faApple } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function BoxOnTwo() {
  return (
    <section className="pb-16 bg-white-200 relative pt-32">
      <div className="container mx-auto">
        <div className="flex flex-wrap justify-center bg-white shadow-xl rounded-lg -mt-64 py-16 px-12 relative z-10">
          <div className="w-full text-center lg:w-8/12">
            <p className="text-4xl text-center mb-2">
              <span role="img" aria-label="love">
                😍
              </span>
            </p>
            <h3 className="font-semibold text-3xl">Vous aimez notre concept ?</h3>
            <p className="text-pink-500 text-lg leading-relaxed mt-4 mb-4">
              Alors n'hésitez pas à nous suivre et à nous donner votre avis sur l'application !
            </p>
            <div className="sm:block flex flex-col mt-10">
              <a
                href="https://static.mytimi.fr/timi-eip/timi-mobile-app/com.timi.TimiMobile-v1.5-0-g68997a9-r199-release.apk"
                target="_blank"
                className="github-star sm:ml-1 text-white font-bold px-3 pt-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-pink-700 active:bg-pink-600 uppercase text-sm shadow hover:shadow-lg"
              >
                <FontAwesomeIcon icon={faAndroid} className="text-lg mr-2" />
                <span> Téléchargez et notez l'application</span>
                <FontAwesomeIcon icon={faApple} className="text-lg ml-3" />
              </a>
            </div>
            <div className="text-center mt-16"></div>
          </div>
        </div>
      </div>
    </section>
  );
}
