import LogoTimi from "../assets/images/Pictures/logoTImi.png"
import MockupHeader from "../assets/images/Pictures/phoneHeader.png"

export default function Header({ title, description }: Readonly<{ title: string, description: string }>) {
  return (
    <section className="header relative pt-16 items-center flex h-screen max-h-860-px">
      <div className="container mx-auto items-center flex flex-wrap">
        <div className="w-full md:w-8/12 lg:w-6/12 xl:w-6/12 px-4">
          <div className="pt-16 sm:pt-0">
            <img src={LogoTimi} width={200} className="mb-8 reveal-1" alt="logo" />
            <h2 className="font-semibold text-4xl text-purple-600 reveal-2">{title}</h2>
            <p className="mt-4 text-lg leading-relaxed text-blue-gray-500 text-justify  reveal-3">
              {description}
            </p>
            <div className="mt-12">
              <a
                href="https://static.mytimi.fr/timi-eip/timi-mobile-app/com.timi.TimiMobile-v1.5-0-g68997a9-r199-release.apk"
                className="github-star ml-1 text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-pink-600 hover:bg-pink-700 active:bg-pink-700 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150 reveal-4"
                target="_blank"
              >
                Télécharger
              </a>
              <div className="mt-12">
              <a
                href="https://web.mytimi.fr"
                className="github-star ml-1 text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-pink-600 hover:bg-pink-700 active:bg-pink-700 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150 reveal-4"
                target="_blank"
              >
                Application commerçant
              </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <img
        className="hidden md:flex absolute top-0 b-auto right-0 pt-32 sm:w-6/12 -mt-48 sm:mt-0 w-10/12 max-h-860px px-16 reveal-phones"
        alt="timi"
        src={MockupHeader}
      />
    </section>
  );
}
